import React, { Component } from 'react'
import { NavBarTop, Jumbotron } from '../molecules';
import { path } from '../../navigation';
import { ProjectService } from '../../logic/domain/services/ProjectService';

export class Projects extends Component {
  constructor(props){
    super(props)
    this.projectService = new ProjectService();
    this.handleCreate = e =>{
      e.preventDefault()
      //console.log(e.target)
      let form = new FormData(e.target)
      this.projectService.create(form).then((response) =>{
        console.log(response)
        alert("Proyecto creado")
        window.location.href = path('index')
      }).catch(({response})=>{
        console.log(response)
        alert("Error")
      })
    }
  }
  
  render() {
    return (
      <div>
        <NavBarTop/>
        <div className="container">
          <Jumbotron title="Crear proyecto">
            <form onSubmit={this.handleCreate}>
              <div className="form-group">
                <label for="name">Nombre del proyecto *</label>
                <input type="text" required className="form-control" name="name"/>
              </div>
              <button type="submit" className="btn btn-primary">Crear</button>
            </form>
          </Jumbotron>
        </div>
      </div>
    )
  }
}
