import React, { Component } from 'react'
import { path } from '../../navigation';
import { NavBarTop, Jumbotron } from '../molecules';
import {ProjectService} from '../../logic/domain/services/ProjectService'
import { CampaignService } from '../../logic/domain/services/CampaignService';

export class Campaigns extends Component {
  constructor(props){
    super(props)
    this.projectService = new ProjectService()
    this.campaignService = new CampaignService()
    this.state = {
      projects: []
    }
    this.handleCreate = e =>{
      e.preventDefault()
      //console.log(e.target)
      let form = new FormData(e.target)
      this.campaignService.create(form)
      .then((response) =>{
        console.log(response)
        alert("Campaña creada")
        window.location.href = path('index')
      }).catch(({response})=>{
        console.log(response)
        alert("Error")
      })
    }

  }
  
  componentDidMount(){
   this.projectService.retrieve()
     .then(({data}) => {
       this.setState({projects: data});
     }).catch(({response}) => {
       console.log(response)
     })
  }
  render() {
    return (
      <div>
        <NavBarTop/>
        <div className="container">
          <Jumbotron title="Crear campaña">
            <form onSubmit={this.handleCreate}>
              <div className="form-group">
                <div className="row">
                  <div className="col-3">
                   <label for="project">Proyecto *</label>
                    <select name="project_id" className="form-control">
                      {this.state.projects.map(p=><option value={p.id}>{p.name}</option>)}
                    </select>
                  </div>
                  <div className="col-3">
                    <label for="name">Nombre de la Campaña *</label>
                    <input type="text" required className="form-control" name="name"/>
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-primary">Crear</button>
            </form>
          </Jumbotron>
        </div>
      </div>
    )
  }
}
