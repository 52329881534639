import React, { Component } from 'react'
import { ProjectService, CampaignService } from '../../logic/domain/services';

export class ImageDetails extends Component {
  constructor(props){
    super(props)
    this.projectService = new ProjectService();
    this.campaignService = new CampaignService();
    this.state = {
      campaigns: [],
      currProject: [],
      currCampaign: [],
    }
    this.handleCurrentProject = (e, prj) =>{
      let cmp = []
      this.props.projects.find(p => p.id == prj.id).campaigns.map(c => 
        cmp.push(<a className="dropdown-item p-3" href="#" onClick={()=>this.props.handleCurrentCampaign(c)}>{c.name}</a>)
      )
      this.setState({campaigns: cmp, currProject: prj})
      this.setState({currCampaign: []})
      console.log(this.state.currProject)
    }

    this.handleProjectCreation = e => {
      e.preventDefault()
      document.querySelector('.dropdown-menu.col-11.show').classList.remove("show");

      let payload = new FormData()
      payload.append('name', e.target.name.value)
      e.target.name.value = ""

      this.projectService.create(payload).then(({data})=>{
        this.setState({currProject: data})
      }).catch(({response})=>{
        console.log(response)
        alert("Error!")
      })
    }
    this.handleCampaignCreation = e => {
      e.preventDefault()
      if (this.state.currProject.length==0) {
        alert("Antes debes seleccionar un proyecto")
        return
      }
      let payload = new FormData()
      payload.append('name', e.target.name.value)
      payload.append('project_id', this.state.currProject.id)
      //e.target.submit.disabled = true;
      document.querySelector('.dropdown-menu.col-11.show').classList.remove("show");
      e.target.name.value = ""
      
      this.campaignService.create(payload).then(({data})=>{
        this.props.handleCurrentCampaign(data)
      }).catch(({response})=>{
        console.log(response)
        alert("Error!")
      })
    }
  }

  dropdownStyle = {
    maxHeight: '50vh',
    overflow: 'auto',
  }
  componentDidUpdate(){
    
  }
  componentDidMount(){

  }
  render() {
    return (
      <form className="bg-light p-5">
          <div className="mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text">Proyecto</label>
              <div className="dropdown col-10">
                <div className="custom-select project" type="button" data-toggle="dropdown">{this.state.currProject.name}</div>
                <ul className="dropdown-menu col-11 p-3" style={this.dropdownStyle}>
                  <li>
                  <form className="input-group" onSubmit={this.handleProjectCreation}>
                      <input type="text" required name="name" placeholder="Nombre de proyecto" className="form-control" style={{borderRadius: '8px'}}/>
                      <span className="input-group-btn">
                          <button type="submmit" name="submit" className="btn btn-secondary ml-2" title="Crear" style={{borderRadius: '8px'}}>
                              <span className="fa fa-plus"></span>
                          </button>
                      </span>
                  </form>
                  </li>
                  {this.props.projects.map(p =>
                      <a className="dropdown-item p-3" href="#" onClick={(e)=> this.handleCurrentProject(e, p)}>{p.name}</a>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text">Campaña</label>
              <div className="dropdown col-10">
                <div className="custom-select" type="button" data-toggle="dropdown">{this.props.currCampaign.name}</div>
                <ul className="dropdown-menu col-11 p-3" style={this.dropdownStyle}>
                  <li>
                    <form className="input-group" onSubmit={this.handleCampaignCreation}>
                        <input type="text" required name="name" placeholder="Nombre de campaña" className="form-control" style={{borderRadius: '8px'}}/>
                        <span className="input-group-btn">
                          <button type="submmit" name="submit" className="btn btn-secondary ml-2" title="Crear" style={{borderRadius: '8px'}}>
                              <span className="fa fa-plus"></span>
                          </button>
                        </span>
                    </form>
                  </li>
                  {this.state.campaigns}
              </ul>
            </div>
          </div>
        </div>
      </form>
    )
  }
}
