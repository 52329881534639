import React, { Component } from 'react'
import { NavBarTop, Jumbotron, HomeCard } from '../molecules';
import { CampaignService, ImageService, ProjectService } from '../../logic/domain/services';
import {previewImages} from './../../utils/previewImages'
import { Button } from '../atoms';
import { ImageDetails } from '../organisms/ImageDetails';
import { loadPath } from '../../config/imageLoader';

export class ImagesAdd extends Component {
  constructor(props){
    super(props);
    this.projectService = new ProjectService();
    this.campaignService = new CampaignService();
    this.imageService = new ImageService();
    this.state = {
        projects: [],
        campaign: [],
        cards: [],
        progress: 0,
        currCampaign: []
    };
    this.dropArea = '';
    this.uploadProgress = [];
    this.progressBar = '';
    this.cards = [];
    this.images = [];
    this.refFile = React.createRef();

    this.preventDefaults = this.preventDefaults.bind(this);
    this.previewFile = this.previewFile.bind(this);
    this.highlight = this.highlight.bind(this);
    this.unhighlight = this.unhighlight.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.updateProgress = this.updateProgress.bind(this);

    this.handleCurrentCampaign = (c) =>{
      this.setState({currCampaign: c})
    }
    this.handleChange = e => {
      this.setState({ [e.target.name]: e.target.value });
    }
    this.handleConsole = e =>{
      console.log(this.state.currCampaign)
    }
  }
      
  preventDefaults (e) {
    e.preventDefault()
    e.stopPropagation()
  }

  highlight(e) {
    this.dropArea.style.borderColor = '#d9e226'
    this.dropArea.classList.add('highlight')
  }

  unhighlight(e) {
    this.dropArea.style.borderColor = '#007bff'
    this.dropArea.classList.remove('active')
  }

  handleDrop(e) {
    if (this.state.currCampaign.length==0) {
      alert("Antes de subir selecciona una campaña")
      return
    }
    var dt = e.dataTransfer
    var files = dt.files

    this.handleFiles(files)
  }

  updateProgress(fileNumber, percent) {
    this.uploadProgress[fileNumber] = percent
    let total = this.uploadProgress.reduce((tot, curr) => tot + curr, 0) / this.uploadProgress.length
    console.debug('update', fileNumber, percent, total)
    // this.progressBar.value = total

    // this.progressBar.textContent = total + "%"
    this.progressBar.style.width = total + "%"
  }

  initializeProgress(numFiles) {
    this.progressBar.style.display = 'flex'
    this.progressBar.value = 0
    this.uploadProgress = []

    for(let i = numFiles; i > 0; i--) {
      this.uploadProgress.push(0)
    }
  }

  handleFiles(files) {
    files = [...files]
    this.initializeProgress(files.length)
    document.getElementById("drop-area").style.display = 'none'
    document.getElementById("upload-container").style.alignItems = 'unset';
    files.forEach(f=>{
      f.rname = [...Array(15)].map(i=>(~~(Math.random()*36)).toString(36)).join('') + '.' + f.name.substr(f.name.lastIndexOf('.') + 1)
    })
    files.forEach(this.previewFile)
    files.forEach(this.uploadFile)
  }

  previewFile(file) {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = function() {
      this.setState({cards: [this.state.cards,
        <div className={"col-lg-6 col-md-6 col-sm-12 pb-2"}>
            <div className="card">
              {/* <i className="fas fa-times-circle" style={this.delBtn}></i> */}
              <i className="m-2" style={this.delBtn}>{window.location.protocol + '//' + window.location.host + '/uploads/' + file.rname}</i>
              <img className="img img-fluid card-img-top" src={reader.result} />
            </div>
        </div>
      ]})
    }.bind(this)
  }

  uploadFile(file, i) {

    const payload = new FormData()
    payload.append('image', file)
    payload.append('campaign_id', this.state.currCampaign.id)
    payload.append('uri', file.rname)

    console.log('payload', ...payload)
    this.imageService.create(payload,      {
      onUploadProgress: e => this.updateProgress(i, (e.loaded * 100.0 / e.total) || 100)
    }).then((result)=>{
        this.updateProgress(i, 100)
        console.log(result)
        setTimeout(() => {
          this.progressBar.style.backgroundColor = '#28a745'
          setTimeout(() => {
            this.progressBar.style.display = 'none'
          }, 1000);
        }, 2000);
    }).catch(({response})=>{
      this.progressBar.style.backgroundColor = '#dc3545'
      console.log(response)
      alert("Error!")
    })
  }

  componentDidMount(){
    this.dropArea = document.getElementById("drop-area")
    this.progressBar = document.getElementById('progress-bar')

    // Prevent default drag behaviors
    ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      this.dropArea.addEventListener(eventName,this.preventDefaults, false)   
      document.body.addEventListener(eventName,this.preventDefaults, false)
    })

    // Highlight drop area when item is dragged over it
    ;['dragenter', 'dragover'].forEach(eventName => {
      this.dropArea.addEventListener(eventName, this.highlight, false)
    })

    ;['dragleave', 'drop'].forEach(eventName => {
      this.dropArea.addEventListener(eventName, this.unhighlight, false)
    })


    // Handle dropped files
    this.dropArea.addEventListener('drop', this.handleDrop, false)

    this.projectService.retrieve().then(({data}) => {
      this.setState({projects: data});
      console.log(data)
    }).catch(({response}) => {
      console.log(response)
    })

  }

  uploadContainer = {
    minHeight:'69vh',
    textAlign: 'center',
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  }

  dragNDrop = {
    borderStyle: 'dotted',
    borderRadius: '30px',
    borderColor: '#007bff',
    padding: '2em',
  }

  img = {
    maxWidth: '42vh'
  }

  delBtn = {
    display: 'flex',
    position: 'absolute',
    color: 'white',
    fontSize: '1em',
    borderRadius: '13px',
    backgroundColor: 'black',
    right: '0',
    padding: '.5em',
    opacity: '.7',
  }


  render() {
    return (
      <div>
        <NavBarTop/>
        <div className="mt-5 pt-5">
          <div className="row" style={{display: 'flex', justifyContent: 'center'}}>

          <div className="col-sm-7">
              <div id="progress-bar" className="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{height: '10px', width:'1%', display: 'none'}}></div>
              <div className="jumbotron">
                <div id="upload-container" style={this.uploadContainer}>

                    <div id="drop-area" className="border-dance" style={this.dragNDrop}>
                      <div>
                        <i className="fas fa-cloud-upload-alt" style={{fontSize: '6em', color: '#007bff'}}></i>
                        <p>Arrastre y suelte los archivos a subir aquí</p>
                      </div>
                      {/* <label htmlFor="selected-images" className="btn btn-primary">Seleccionar imágenes</label>
                      <input id="selected-images"  ref="file" className="custom-file-input input-hidden" type="file" lang="es" name="images[]" multiple="multiple"></input>  */}    
                    </div>

                    {this.state.cards}
                    
                </div>
              </div>
            </div>


            <div className="col-sm-4">
                <ImageDetails
                  projects={this.state.projects}
                  currCampaign={this.state.currCampaign}
                  handleCurrentCampaign={this.handleCurrentCampaign}
                />
            </div>
          </div>
        </div>
      </div>
    )
  }
}