
import React, { Component } from 'react'
import {Link} from "react-router-dom"

export class NavLink extends Component {
  render() {
    return (
        <li className="nav-item">
            <Link className={"nav-link " + (this.props.active ? 'active' : '')} aria-current="page" to={this.props.href}>{this.props.text}</Link>
        </li>
    )
  }
}