import React, { Component } from 'react'

export class BtnLight extends Component {
  render() {
    //const { type, theme, size, onClick, children, className, disabled} = Props
    return (
      <a className={"btn btn-" + (this.props.color? this.props.color : 'light')} {...this.props}>{this.props.text}</a>
    )
  }
}
