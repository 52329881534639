/**
 * @author: Juan López Tello
 */

import React from "react";
import webroutes from "./routes"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollTop from "./ScrollTop"
import {NotFound} from '../presentation/pages'

const routes = []
        .concat(webroutes);

export const path = (routeName, params = false) => {
    let route = routes.find(route => route.name === routeName).path;
    if (params) {
        let parsed = route;
        for (let [k, v] of Object.entries(params)) {
            if (route.match(`:${k}`)) {
                parsed = parsed.replace(`:${k}`, v);
            }
        }
        return parsed;
    }
    return route;
};

export default function AppRoutes() {
    return (
        <Router>
            <ScrollTop>
                <Switch>        
                    {routes.map(route => <route.Component path={route.path} exact={route.exact===true} key={route.path} component={route.component}/>)}
                    <Route component={NotFound} />
                </Switch>
            </ScrollTop>
        </Router>
    );
}
