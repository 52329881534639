import React, { Component } from 'react'

export class Jumbotron extends Component {
  render() {
    return (
        <div className="jumbotron">
            <h1 className="display-4">{this.props.title}</h1>
            <p className="lead">{this.props.desc}</p>
            <hr className="my-4"/>
            {this.props.children}
        </div>
    )
  }
}
