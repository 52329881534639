import axios from 'axios';
import { PROJECTS_ENDPOINT } from '../../../config/api/endpoints';

/**
 * @author Juan López Tello
 * */
export class ProjectService {
    constructor() {}
    retrieve(id=false) {
        const endpoint = id ? `${PROJECTS_ENDPOINT}${id}` : PROJECTS_ENDPOINT;
        return axios.get(endpoint).then(response => response);
    }
    create(payload) {
        return axios.post(PROJECTS_ENDPOINT, payload);
    }
}