import React, { Component } from 'react'
import { ActionBar, NavBarTop, Jumbotron } from '../molecules';
import { ImageService, ProjectService } from '../../logic/domain/services';
import { loadPath } from '../../config/imageLoader';
import { path } from '../../navigation';
import Masonry from 'react-masonry-component';
 
export class Images extends Component {
  constructor(props){
    super(props)
    this.projectService = new ProjectService()
    this.imageService = new ImageService()
    this.state = {
      projects: [],
      campaigns: [],
      images: [],
      galleryItems: []
    }
    this.handleCampaignSearchFilter = this.handleCampaignSearchFilter.bind(this)
    this.handleDelete = (e, i) => {
      e.preventDefault()
      if (window.confirm('¿Estas seguro? esta acción no se puede revertir.')){
          this.imageService.delete(i.id).then(({data})=>{
            this.setState({images: this.state.images.filter(im => im != i)})
            console.log(this.state.images)
          }).catch(({response})=>{
              console.log(response)
              alert('Error!')
          })
      }
    }
  }

/**
 * Function to sort alphabetically an array of objects by some specific key.
 * 
 * @param {String} property Key of the object to sort.
 */
  dynamicSort(property) {
    var sortOrder = 1;

    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a,b) {
        if(sortOrder == -1){
            return b[property].localeCompare(a[property]);
        }else{
            return a[property].localeCompare(b[property]);
        }        
    }
  }
  componentDidMount(){
    const array_column = (array, column) => array.map(e => e[column]);
    this.projectService.retrieve().then(({data})=>{
      this.setState({projects: data}) 
      data.map(e =>this.setState({campaigns: this.state.campaigns.concat(e['campaigns'])}))
      // let ccc=[]
      // data.map(e => ccc = [...ccc, ...e['campaigns']] )
      // data.map(e => ccc = ccc.concat(e['campaigns']))
      // data.map(e => this.setState({campaigns: [...this.state.campaigns, ...e['campaigns']]}) )
      // console.log(ccc)
    }).catch(({response})=>{
      console.error(response)
    })
    this.imageService.retrieve().then(({data})=>{
      this.setState({images: data})
    }).catch(({response})=>{
      console.error(response)
    })
  }
  badges = {
    padding: '1em 2em',
    color: '#007bff',
    fontSize: '100%',
    boxShadow: 'rgba(0, 0, 0, 0.075) 0px 1px 6px 1px'
  }
  handleProjectImages(p){
    this.setState({campaigns: p.campaigns, images: p.campaigns.map(c=>c['images'])[0] || []})
  }
  handleCampaignImages(c){
    this.setState({images: c.images})
  }
  handleCampaignSearchFilter(e){
    this.state.campaigns.map((c,i)=>{
      let citem = document.querySelector("#list-tab .list-group-item:nth-child("+(i+1)+")")
      if(!new RegExp(e.target.value.toLowerCase()).test(c.name.toLowerCase())){
        citem.style.display = "none"
      } else{
        citem.style.display = "block"
      }
    })
  }
  render() {
    return (
      <div>
        <NavBarTop/>
        <div className="mt-5 pt-5 pb-4" style={{display:'flex', flexFlow: 'wrap', justifyContent: 'center'}}>
          {this.state.projects.map(p=><a href="#" onClick={()=>this.handleProjectImages(p)} className="badge badge-pill badge-light m-2" style={this.badges}>{p.name}</a>)}
        </div>
        <div className="row">
        <div className="col-2 ml-4">
          <form className=" list-group-item form-inline">
            <i className="fas fa-search" aria-hidden="true"></i>
            <input onChange={this.handleCampaignSearchFilter} className="form-control form-control-sm ml-3 w-75" style={{borderRadius: '14px'}} type="text" placeholder="Buscar campaña" aria-label="Search"/>
          </form>
          <div className="list-group" id="list-tab" role="tablist">
            {this.state.campaigns.map(c => 
              <a className="list-group-item list-group-item-action" onClick={()=>this.handleCampaignImages(c)} id="list-home-list" data-toggle="list" href="#list-home" role="tab" aria-controls="home">{c.name}</a>
            )}
          </div>
        </div>
        <div className="col-9">
          <Masonry>
            {this.state.images.slice(0, 80).map(img=>
              <div className="col-md-3 pb-2 container-card mb-3">
              <div className="card" style={{filter: 'drop-shadow(0 0 5px rgba(0,0,0,.1))', border: 'none'}}>
                  <a href={path('update_image', {id: img.id})}><img className="card-img-top" src={loadPath(img.uri, true)} ></img></a>
                        <div className="card-body">
                            <h5 className="card-title">{img.name}</h5>
                            <div className="alert-light" >
                              <span className="copy" title="Copiar enlace">{loadPath(img.uri)}</span> 
                              <hr/>
                            </div>
                            <div className="btn-group" role="group" aria-label="Basic example">
                                <a href={loadPath(img.uri)} className="btn btn-secondary btn-sm" target="_blank" data-container="body" data-toggle="tooltip" data-placement="bottom" title="Abrir en nueva pestaña">
                                  <i className="fas fa-external-link-alt" aria-hidden="true"></i>
                                </a>
                                <a href={path('update_image', {id: img.id})} className="btn btn-secondary btn-sm" data-container="body" data-toggle="tooltip" data-placement="bottom" title="Actualizar fotografía">
                                  <i className="fas fa-edit" aria-hidden="true"></i>
                                </a>
                                <a download href={loadPath(img.uri)} className="btn btn-secondary btn-sm" target="_blank" download="" data-container="body" data-toggle="tooltip" data-placement="bottom" title="Descargar">
                                  <i className="fa fa-download" aria-hidden="true"></i>
                                </a>
                                <a onClick={(e) => this.handleDelete(e, img)} className="btn btn-danger btn-sm" data-container="body" data-toggle="tooltip" data-placement="bottom" title="Eliminar">
                                  <i className="fas fa-trash" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
              )}
            </Masonry>
          </div>
          </div>
        </div>
    )
  }
}
