import axios from 'axios';
import { IMAGES_ENDPOINT } from '../../../config/api/endpoints';

/**
 * @author Juan López Tello
 * */
export class ImageService {
    constructor() {}
    retrieve(id=false) {
        const endpoint = id ? `${IMAGES_ENDPOINT}${id}` : IMAGES_ENDPOINT;
        return axios.get(endpoint).then(response => response);
    }
    create(payload, config) {
        return axios.post(IMAGES_ENDPOINT, payload, config);
    }
    update(id, payload) {
        return axios.put(`${IMAGES_ENDPOINT}${id}`, payload);
    }
    delete(id) {
        return axios.delete(`${IMAGES_ENDPOINT}${id}`);
    }
}