/**
 * @author: Juan López Tello
 */

import * as React from "react"

export const router = {
    routes: [],
    namespace: '',
    map: (Component, name, path, component, args = false) => {
        router.routes.push({
            Component,
            name,
            path: router.namespace + path,
            component: component,
            exact: true
        })
    },
    group: (namespace, callback) => {
        console.log(callback(router));
        router.namespace = namespace;
    }
}

console.log(router.routes);

export const render = (Component, props) => {
    return <Component {...props} />
}

/*
router.group(PrivateRoute, '/admin', () => { // parent Component
    router.map(Route, '/slider', ()=>{ // Inherit parent Component until an override scenario
        return <div>hello</div>;
    });
    router.map(PrivateRoute, '/slider2', ()=>{
        return <div>hello</div>;
    });
}
);*/