/**
 * @author Juan López Tello
 * */
import { ENVIRONMENT } from "./config";
import video_img from "../images/files/video-vector.png";
import document_img from "../images/files/document-vector.png";
import pdf_icon from "../images/files/pdf.jpg";
import docx_icon from "../images/files/docx.jpg";
import wmv_icon from "../images/files/wmv.jpg";
import mp4_icon from "../images/files/mp4.jpg";
import m4v_icon from "../images/files/m4v.jpg";
import mpeg_icon from "../images/files/mpeg.jpg";
import mpg_icon from "../images/files/mpg.jpg";
import avi_icon from "../images/files/avi.jpg";

export const loadPath = (uri, fallbackicon = false) => {
    if (fallbackicon) {
        let icon = getFileTypeIcon(uri)
        if (icon) {
            return icon
        }
    }

    if (ENVIRONMENT == 'prod') {        
        return window.location.protocol + '//' +window.location.host + '/uploads/' + uri
    } else {
        return process.env.PUBLIC_URL + '/uploads/' + uri
    }
}

const getFileExtension = (uri) => {return uri.substr(uri.lastIndexOf('.') + 1)}
const isVideo = (uri) => {return (/flv|mp4|m4v|mkv|webm|vob|ogv|ogg|avi|mpg|mpeg|wmv/).test(getFileExtension(uri))}
const isDocument = (uri) => {return (/pdf|docx|xlsx|txt|pptx|ppt/).test(getFileExtension(uri))}

const getFileTypeIcon = (uri) => {
    switch (getFileExtension(uri)) {
        case 'pdf':
            return pdf_icon
            break;
        case 'docx':
            return docx_icon
            break;
        case 'mp4':
            return mp4_icon
            break;
        case 'wmv':
            return wmv_icon
            break;
        case 'm4v':
            return m4v_icon
            break;
        case 'mpeg':
            return mpeg_icon
            break;
        case 'mpg':
            return mpg_icon
            break;
        case 'avi':
            return avi_icon
            break;
        default:
            return false;
            break;
    }
}