import React, { Component } from 'react'
import { NavLink } from '../atoms';
import {path} from '../../navigation'
import formula_logo from '../../images/formula-logo.png'
export class NavBarTop extends Component {
  handleLogout(){
    localStorage.removeItem('loggedIn');
    window.location = path('login')
  }
  render() {
    return (
        <nav className="navbar fixed-top navbar-expand-lg" style={{backgroundColor: 'white', boxShadow: '0 2px 6px 2px rgba(0, 0, 0, 0.075)'}}>
            <div className="collapse navbar-collapse" id="navbarNav">
              <a href="/" className="navbar-brand d-flex align-items-center">
                <img src={formula_logo} style={{height: '35px'}}/>
              </a>
              <a href={path('upload')} title="Subir imágenes" className="navbar-brand d-flex align-items-center">
                <a className="btn btn-light btn-lg" role="button"><i className="fa fa-plus"/></a>
              </a>
              <ul className="navbar-nav mr-auto">
                  <NavLink href={path('index')} text="Imágenes"/>
                  {/* <NavLink href={path('projects')} text="Proyectos"/>
                  <NavLink href={path('campaigns')} text="Campañas"/> */}
              </ul>
              <li className="form-inline my-2 my-lg-0">
                <button className="btn btn-outline-danger my-2 my-sm-0" type="submit" onClick={this.handleLogout}>
                  <i className="fas fa-sign-out-alt"></i> Cerrar sesión
                </button>
              </li>
            </div>
        </nav>
    )
  }
}
