/**
 * @author: Juan López Tello
 */

import * as React from "react";
import {router, render} from './router'
import {
    Route,
} from "react-router-dom";
import { ImagesAdd, Projects, Campaigns, Images, ImagesUpdate } from "../presentation/pages";
import SignIn, { PrivateRoute } from "../auth/signIn";

router.map(Route,'login', '/login', SignIn);
router.map(PrivateRoute, 'index', '/', Images);
router.map(PrivateRoute, 'projects', '/projects', Projects);
router.map(PrivateRoute, 'campaigns', '/campaigns', Campaigns);
router.map(PrivateRoute, 'upload', '/upload', ImagesAdd);
router.map(PrivateRoute, 'update_image', '/images/:id', ({match}) =>{
    return render(ImagesUpdate, match.params)
});

export default router.routes;