import React, { Component } from 'react'

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: true
        }
        this.handleLogin = this.handleLogin.bind(this)
    }

    handleLogin = e =>{
        e.preventDefault();
      
        const { username, password } = this.state;
        const { history } = this.props;
        this.setState({ error: false });
      
        if (!(username === 'formula' && password === 'CI-R2D2')) {
          alert("Usuario o contraseña invalida")
          return this.setState({ error: true });
        }
        localStorage.setItem('loggedIn', true);
        this.props.logear()
    }

    setStateFromInput = (event) =>{
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    }
    render() {
        return (
            <div className="text-center" style={{width: '100vw', height: '100vh', display: 'flex'}}>
                <form className="form-signin" onSubmit={this.handleLogin}>
                    <h1 className="h3 mb-3 font-weight-normal">Iniciar Sesión</h1>
                    <label for="username" className="sr-only">Usuario</label>
                    <input type="text" onChange={this.setStateFromInput} name="username" className="form-control" placeholder="Ingrese usuario" required autofocus/>
                    <label for="password" className="sr-only">Contraseña</label>
                    <input type="password" onChange={this.setStateFromInput}  name="password" className="form-control" placeholder="Ingrese contraseña" required/>
                    {/* <div className="checkbox mb-3">
                    <label>
                        <input type="checkbox" value="remember-me"> Remember me
                    </label>
                    </div> */}
                    <button className="btn btn-lg btn-primary btn-block" type="submit" >Ingresar</button>
                    <p className="mt-5 mb-3 text-muted">&copy; <a href="https://formulaperu.com">Formula Perú</a> - 2019</p>
                </form>
            </div>
        )
    }
}