import React, { Component } from 'react'

export class NotFound extends Component {
  render() {
    return (
      <div>
        <h1>404, ESTA PAGINA NO EXISTE</h1>
      </div>
    )
  }
}