import React, { Component } from 'react'
import { NavBarTop, Jumbotron, HomeCard } from '../molecules';
import {path} from '../../navigation'
export class Dashboard extends Component {
  render() {
    return (
      <div>
        <NavBarTop/>
        <div className="container">
          <Jumbotron title="Dashboard" desc="Selecciona uno de los bloques.">
            <div className="row">
              <HomeCard title="Proyectos" desc="Crea proyectos" href={path('projects')} btn="Visualizar" />
              <HomeCard title="Campañas" desc="Crea campañas" href={path('campaigns')} btn="Visualizar" />
              <HomeCard title="Imágenes" desc="Añade imágenes" href={path('index')} btn="Visualizar" />
            </div>
          </Jumbotron>
        </div>
      </div>
    )
  }
}
