import React, { Component } from 'react'

export class NavTab extends Component {
  render() {
    return (
        <li className="nav-item">
            <a className={"nav-link" + (this.props.active ? ' active' : '')} id={this.props.text + "-tab"} data-toggle="tab" href={"#" + this.props.text} role="tab" aria-selected={this.props.selected}>{this.props.text}</a>
        </li>
    )
  }
}
