import React from "react"
import {
    Route,
    Redirect,
    withRouter
} from "react-router-dom"
// import AdminHeader from "../../theme/AdminHeader"
// import AdminFooter from "../../theme/AdminFooter"
import { path } from "../navigation";
import Login from "../presentation/pages/Login";

//PAGINA LOGIN
export default class SignIn extends React.Component {
    state = { 
        redirectToReferrer: false
    };
    
    login = () => {
        console.log(4)
        console.log("login");
        fakeAuth.authenticate(() => {
            this.setState({ redirectToReferrer: true })
        });
    };
    
    render() {
        console.log("render");
        let { from } = this.props.location.state || { from: { pathname: path('index') } };
        let { redirectToReferrer } = this.state;
        
        if (redirectToReferrer) return <Redirect to={from} />;
        
        return (
            <Login logear={this.login}/>
            );
        }
    }
    
    //COMPROBACION DE LOGIN
    const fakeAuth = {
        isAuthenticated: localStorage.getItem('loggedIn'),
        authenticate(cb) {
            this.isAuthenticated = true;
            setTimeout(cb, 100);
        },
        signout(cb) {
            this.isAuthenticated = false;
            setTimeout(cb, 100);
        }
    };
    
    // //TEMPLATE
    // export const StructureHeader = withRouter(
    //     ({ history }) =>
    //         fakeAuth.isAuthenticated ? (
    //             <AdminHeader fakeAuth={fakeAuth} history={history}/>
    //         ) : (
    //             null
    //         )
    // );
    
    // export const StructureFooter = withRouter(
    //     ({ history }) =>
    //         fakeAuth.isAuthenticated ? (
    //         <AdminFooter/>
    //         ) : (
    //            null
    //         )
    // );
    
    export function PrivateRoute({ component: Component, ...rest }) {
        return (
            <Route 
            {...rest}
            render={
                props =>fakeAuth.isAuthenticated ? (<Component {...props} />) : 
                (<Redirect to={{pathname: path('login') ,state: { from: props.location }}}/>)
            }/>
        )
    }
                