import React, { Component } from 'react'

export class TabPane extends Component {
  render() {
    return (
        <div className={"tab-pane fade" + (this.props.active ? ' show active':'')} id={this.props.text} role="tabpanel" aria-labelledby={this.props.text + "-tab"}>
           {this.props.children}
        </div>
    )
  }
}
