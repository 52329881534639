/**
 * @author Juan López Tello
 * */
export let ENVIRONMENT = 'prod';
export let API_HOST = 'https://'+ window.location.host+'/api'

if ((/localhost|3000|test/).test(window.location.host)) {
    ENVIRONMENT = 'dev'
    API_HOST = 'http://127.0.0.1:8000'
    console.warn('React App environment: ' + ENVIRONMENT)
}