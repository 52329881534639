import React, { Component } from 'react'
import {previewImages} from "../../utils/previewImages"
import { NotFound } from './NotFound';
import { ImageService } from '../../logic/domain/services';
import { NavBarTop } from '../molecules';
import { loadPath } from '../../config/imageLoader';

// import update from 'react-addons-update';

export class ImagesUpdate extends Component {
    constructor(props){
        super(props)
        this.imageService = new ImageService();
        this.state = {
            view: false,
            image: "",
            payload: {},
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleImagePreview = (e) => {
            const file = e.target.files[0]
            switch (e.target.name) {
                case "landscape":
                    this.setState(({payload}) => payload.image = file)
                    break;
            }
            return previewImages(e);
        }

    }
    handleSubmit(e) {
        e.preventDefault();
        const payload = new FormData()
        Object.entries(this.state.payload).forEach(
            ([key, value]) => {
                if (value != null) {
                    payload.append(key, value)
                }
            }
            )
            
            console.log(...payload)
            
        const submit_btn = e.target.submit
        console.log(submit_btn)
        submit_btn.innerHTML = '<i class="fas fa-upload"></i> Subiendo ....'
        this.imageService.update(this.props.id, payload)
        .then((result)=>{
            submit_btn.innerHTML = '<i class="fa fa-save"></i> Guardar'
            console.log(result)
            alert("Actualización exitosa")
        }).catch((error)=>{
            submit_btn.innerHTML = '<i class="fa fa-save"></i> Guardar'
            alert("Error")
            console.log(error.response)
        })

    }
    handleInput = (e) => {
        const target = e.target
        switch (target.name) {
            case "name":
                this.setState(({payload}) => payload.name = target.value)
                break;
            default:
                break;
        }
    }

    componentWillMount = () =>{
        this.imageService.retrieve(this.props.id)
        .then(({data})=>{
            this.setState(state => (
                state.payload = {
                    name: data.name,
                }
            ))
            this.setState({
                view: true,
                image: loadPath(data.uri),
            })
        }).catch((error)=>{
            console.log(error.response)
            this.setState({view:false})
        })
    }
  render() {
        return (
            <div>
                <NavBarTop/>
                <div className="container pt-5">
                    <div className="jumbotron row mt-5">
                        <div className="col-6">
                            <img alt="" src={this.state.image} id="img_reader_slider" className="img img-fluid img-thumbnail" />
                        </div>
                        <div className="col-6">
                            <h1 className="display-4">Editar archivo</h1>
                            <p className="lead">{this.props.subtitle}</p>
                            <hr className="my-4"></hr>
                            <div className="row">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="col-md-12 col-sm-12 pb-5">
                                        <button type="submit" name="submit" className="btn btn-primary btn-sm pull-right" style={{marginRight:'10px'}}><i className="fa fa-save"></i> Guardar</button>
                                        <button type="button" onClick={() => window.history.back()} className="btn btn-danger btn-sm pull-right"><i className="fa fa-ban"></i> Cancelar <span className="text-meses"></span></button>
                                        <hr></hr>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="" className="control-label">Nombre</label>
                                                    <input type="text" name="name" className="form-control input-sm" placeholder="Ingrese título" value={this.state.payload.name}  onChange={ this.handleInput }/>
                                                    <small className="form-text text-muted">Campo opcional.</small>
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="" className="control-label">Link</label>
                                                    <input type="text" name="link" className="form-control input-sm" placeholder="Ej: http://pagina.com, #seccion" value={this.state.payload.link} onChange={ this.handleInput } />
                                                    <small className="form-text text-muted">Campo opcional.</small>
                                                </div>
                                            </div> */}
                                            <div className="col-md-8">
                                                <p>Imágen</p>
                                                <label htmlFor="selected-image_desktop" className="btn btn-primary"><span className="fa fa-cloud-upload-alt"></span>&nbsp;&nbsp;Reemplazar archivo</label>
                                                <input type="file" lang="es" name="landscape" onChange={(e)=> this.setState({image: this.handleImagePreview(e)}) }  id="selected-image_desktop" className="custom-file-input input-hidden"/>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )  
  }
}