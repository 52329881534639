import React, { Component } from 'react'
import { Card, Button } from '../atoms';

export class HomeCard extends Component {
  render() {
    return (
        <Card title={this.props.title} desc={this.props.desc}>
            <Button href={this.props.href} text={this.props.btn} />
        </Card>
    )
  }
}
